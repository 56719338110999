@import "./node_modules/modern-normalize/modern-normalize.css";

@font-face {
    font-family: "FutoSans";
    src: url("./fonts/FutoSans-Black.woff2") format("woff2"), url("./fonts/FutoSans-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "FutoSans";
    src: url("./fonts/FutoSans-BlackItalic.woff2") format("woff2"),
        url("./fonts/FutoSans-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Graphik";
    src: url("./fonts/Graphik-Regular.woff2") format("woff2"), url("./fonts/Graphik-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Graphik";
    src: url("./fonts/Graphik-RegularItalic.woff2") format("woff2"),
        url("./fonts/Graphik-RegularItalic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Graphik";
    src: url("./fonts/Graphik-MediumItalic.woff2") format("woff2"),
        url("./fonts/Graphik-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Graphik";
    src: url("./fonts/Graphik-Medium.woff2") format("woff2"), url("./fonts/Graphik-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

:root {
    --c-primary: #55d348;
    --c-primary-darker: #50b047;
    --c-dark-blue-1: #041323;
    --c-dark-blue-2: #0a1e33;
    --c-dark-blue-3: #0b2037;
    --c-medium-blue: #1e3247;
    --c-light-blue: #95c8ff;
    --c-dark-green: #14490e;
    --c-orange: #f6ab4c;
    --c-black: #000000;
    --c-white: #ffffff;

    --font-heading: "FutoSans";
    --font-body: "Graphik";
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

html {
    /* Load system fonts */
    font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;

    /* Make type rendering look crisper */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Disable auto-enlargement of small text in Safari */
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;

    /* Enable kerning and optional ligatures */
    text-rendering: optimizeLegibility;
    font-size: 0.625em;
    line-height: 1;
    box-sizing: border-box;
    min-height: 100%;
}

body {
    background: var(--c-dark-blue-3);
    color: var(--c-black);
    font-family: var(--font-body);
    min-height: 100%;
}

/* Text meant only for screen readers. */
.visually-hidden {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
    word-wrap: normal !important;
}

.visually-hidden--inline {
    margin: 0;
    height: 1em;
}

.skip-to-content-link:focus {
    z-index: 9999;
    position: inherit;
    overflow: auto;
    width: auto;
    height: auto;
    clip: auto;
}

.hidden {
    display: none !important;
}

/* base-focus */

*:focus {
    outline: 0;
    box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-family: var(--font-heading);
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--c-white);
}

h1,
.h1 {
    font-size: 50px;
    line-height: 44px;
    letter-spacing: -2px;
}

h2,
.h2 {
    font-size: 40px;
    line-height: 38px;
    letter-spacing: -2px;
}

h3,
.h3 {
    font-size: 36px;
    line-height: 32px;
    letter-spacing: -2px;
}

p {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    margin: 0;
    color: white;
}

a {
    color: var(--c-primary);
    display: inline-block;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    img {
        display: block;
    }
}

img {
    max-width: 100%;
    height: auto;
}

ul{
    margin: 0;
    padding: 0;
}

.btn {
    background: var(--c-primary);
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 80px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-heading);
    font-size: 32px;
    line-height: 28px;
    letter-spacing: -1px;
    color: var(--c-dark-blue-3);
    text-transform: uppercase;
    font-style: italic;
    cursor: pointer;
    transition: all 0.2s ease;
    text-decoration: none;

    &:hover {
        background: var(--c-primary-darker);
    }
}

.btn--link {
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    line-height: 18px;
    color: var(--c-primary);
    text-decoration: none;
    position: relative;
    padding: 0 32px;
}

.rtnu-wrapper--lg {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
}

.rtnu-wrapper {
    max-width: 983px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
}

@media screen and (min-width: 376px) {
    h1,
    .h1 {
        font-size: 56px;
    }
}

// Top bar
.rtnu-top-bar {
    min-height: 58px;
    background: var(--c-orange);
    color: var(--c-dark-blue-3);
    text-align: center;
    font-weight: 500;
    display: flex;
    align-items: center;

    p {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: inherit;
    }

    @media screen and (min-width: 900px) {
        min-height: 60px;

        p {
            font-size: 20px;
            line-height: 20px;
        }
    }
}

// Hamburger Icon
// ==================================================
$hamburger-padding-left: 0px !default;
$hamburger-padding-right: 0px !default;
$hamburger-padding-top: 0px !default;
$hamburger-padding-bottom: 0px !default;
$hamburger-layer-width: 16px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 5px !default;
$hamburger-layer-color: var(--c-dark-blue-3) !default;
$hamburger-layer-border-radius: 2px !default;
$hamburger-hover-opacity: 0.9 !default;
$hamburger-active-layer-color: var(--c-primary) !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

.hamburger {
    padding: $hamburger-padding-top $hamburger-padding-right $hamburger-padding-bottom $hamburger-padding-left;
    display: inline-block;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: var(--c-primary);
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
    width: 38px;
    height: 38px;
    border-radius: 20px;

    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
        } @else {
            opacity: $hamburger-hover-opacity;
        }
    }

    &.is-active {
        background-color: var(--c-dark-blue-3);

        &:hover {
            @if $hamburger-hover-use-filter == true {
                filter: $hamburger-active-hover-filter;
            } @else {
                opacity: $hamburger-active-hover-opacity;
            }
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $hamburger-active-layer-color;
        }
    }
}

.hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: calc($hamburger-layer-height / -2);

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
}

.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

header {
    padding: 24px 0;
    background: var(--c-dark-blue-3);
    width: 100%;

    .rtnu-wrapper--lg {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        align-items: center;
    }

    .rtnu-logo{
        grid-column: 2;
        
        img{
            transition: all 0.2s ease-in-out;
        }
    }

    .rtnu-nav-wrapper{
        grid-column: 3;
        justify-self: end;
    }

    #mobileNavTrigger{
        position: relative;
        z-index: 101;
        transition: all 0.4s ease;
        
        &.is-active{
            transition: all 0.4s ease;
            position: fixed;
            top: 32px;
            right: 20px;
        }
    }

    &.activeScroll{
        padding: 16px 0;
        background: rgba(11,32,55, 0.94);
        backdrop-filter: blur(8px);
        position: sticky;
        width: 100%;
        top: 0;
        transition: all 0.2s ease;
        z-index: 99;
    }

    @media screen and (min-width: 750px) {
        padding: 40px 0;

        #mobileNavTrigger{            
            &.is-active{
                position: relative;
                top: 0;
                right: 0;
            }
        }
    }

    @media screen and (min-width: 990px) {
        .rtnu-logo{
            img{
                height: 80px;
            }
        }

        &.activeScroll{
            .rtnu-logo{
                img{
                    height: 64px;
                }
            } 
        }
    }
}

.rtnu-jd{
    header{
        .rtnu-wrapper--lg {
            justify-items: unset;
            grid-template-columns: repeat(2, 1fr);
        }
        .rtnu-logo{
            grid-column: 1;
    
            img{
                height: 56px;
            }
        }
    
        .rtnu-nav-wrapper{
            grid-column: 2;
            justify-self: end;
        }
    
        .btn{
            max-width: 146px;
            height: 37px;
            font-size: 20px;
            padding: 0 12px;
        }
    
        @media screen and (min-width: 990px) {
            .rtnu-wrapper--lg {
                justify-items: center;
                grid-template-columns: repeat(3, 1fr);
            }
    
            .rtnu-logo{
                grid-column: 2;
        
                img{
                    height: 96px;
                }
            }
    
            .rtnu-nav-wrapper{
                grid-column: 3;
            }
    
            .btn{
                max-width: 212px;
                height: 60px;
                font-size: 24px;
                padding: 0 32px;
            }
    
            &.activeScroll{

                .rtnu-logo{
                    img{
                        height: 64px;
                    }
                }

                .btn{
                    height: 50px;
                    font-size: 22px;
                }
            }
        }
    }
}

.rtnu-nav{
    visibility: hidden;
    opacity: 0;
    transform: translateY(-12px) scale(0.95);
    transform-origin: 100% 0;
    position: absolute;
    z-index: 100;
    inset: 0;
    background: var(--c-primary);
    padding: 101px 20px 56px;
    overflow: auto;
    transition: transform 0.2s cubic-bezier(0.215,0.61,0.355,1);
    will-change: transform;
    pointer-events: none;

    &.active{
        position: fixed;
        transform: translateY(0) scale(1);
        visibility: visible;
        opacity: 1;
        pointer-events: initial;
    }

    ul{
        list-style: none;

        li{
            & + li{
                margin-top: 40px;
            }

            a{
                font-size: 34px;
                line-height: 30px;
                font-family: var(--font-heading);
                font-style: italic;
                text-transform: uppercase;
                color: var(--c-dark-blue-3);
                letter-spacing: -2px;
                text-decoration: none;
                transition: all 0.2s ease-in-out;
                padding-right: 12px;

                &:hover{
                    text-decoration: underline;
                    color: var(--c-dark-green);
                }
            }
        }
    }

    @media screen and (min-width: 750px) {
        padding: 56px 54px 48px;
        border-radius: 16px;
        transition: transform 0.7s cubic-bezier(0.215,0.61,0.355,1);

        &.active{
            position: absolute;
            inset: unset;
            transform: translateY(-12px) scale(1);
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
            top: 0;
            right: 0;
            max-width: 500px;
        }
    }
}

.rtnu-hero{
    padding: 56px 0 88px;
    text-align: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1695' height='824' fill='none' viewBox='0 0 1695 824'%3E%3Cpath fill='%23122A44' d='m1573.24 573.062-34.28-27.426L1280 754l191.85-262.35-34.36-27.498L1605 394l-31.76 179.062ZM433.356 265.697l-40.137-32.118L90 477.591l224.632-307.234-40.222-32.203L470.542 56l-37.186 209.697ZM615.583 674.013l-17.719-14.247L464 768l99.169-136.276-17.756-14.284L632 581l-16.417 93.013Z'/%3E%3C/svg%3E");
    background-size: contain;

    h1{
        margin-bottom: 16px;
    }

    p{
        color: var(--c-light-blue);
    }

    @media screen and (min-width: 990px) {
        padding: 72px 0 144px;
                
        h1{
            font-size: 146px;
            line-height: 120px;
            letter-spacing: -6px;
            margin-bottom: 32px;
        }

        p{
            font-size: 46px;
            line-height: 52px;
            letter-spacing: 0.3px;
            max-width: 792px;
            margin: 0 auto;
        }
    }

    &.rtnu-hero--inner-pages{
        padding: 32px 0 48px;
        background-image: none;

        img{
            height: 73px;
            width: auto;
            margin-bottom: 16px;
        }

        h1{
            font-size: 36px;
            line-height: 37px;
            margin-bottom: 24px;
        }

        p{
            line-height: 30px;
        }

        @media screen and (min-width: 990px) {
            padding: 40px 0 112px;

            img{
                height: 112px;
                margin-bottom: 32px;
            }
            h1{
                font-size: 80px;
                line-height: 72px;
                letter-spacing: -2px;
                margin-bottom: 32px;
            }
    
            p{
                font-size: 32px;
                line-height: 46px;
                letter-spacing: 0.2px;
                max-width: 960px;
                margin: 0 auto;
            }
        }
    }

    &.rtnu-hero--sites{
        @media screen and (min-width: 990px) {
            p{
                max-width: 926px;
            }
        }
    }
}

.rtnu-jd-hero{
    padding: 32px 0 56px;
    text-align: center;

    h1{
        margin-bottom: 24px;
        font-size: 48px;
    }

    p{
        color: var(--c-light-blue);

        & + p{
            margin-top: 24px;
        }
    }

    @media screen and (min-width: 990px) {
        padding: 72px 0 112px;

        h1{
            font-size: 88px;
            line-height: 80px;
            letter-spacing: -3px;
            margin-bottom: 48px;
        }

        p{
            font-size: 32px;
            line-height: 46px;
            letter-spacing: 0.2px;
            max-width: 996px;
            margin: 0 auto;

            & + p{
                margin-top: 40px;
            }
        }
    }
}

.rtnu-testimonials{
    padding: 64px 0 72px;
    background: var(--c-dark-blue-1);
    color: var(--c-white);

    blockquote{
        margin: 0 auto;
        padding: 56px 0 0;
        position: relative;

        &::before{
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='81' height='57' fill='none' viewBox='0 0 81 57'%3E%3Cpath fill='%2355D348' d='M70.6541 38.4311c0 6.3067-1.4288 10.9381-4.2865 13.8944-2.7592 2.8576-7.095 4.2865-13.0075 4.2865-8.9672 0-13.4509-4.3851-13.4509-13.1553 0-14.8797 3.5475-25.8178 10.6425-32.8142C57.7452 3.54749 67.895 0 81.001 0l-1.7738 10.6425c-3.5475 0-7.6862 1.5274-12.4162 4.5821-4.6314 2.9563-7.4399 7.1443-8.4253 12.5641 8.179 0 12.2684 3.5474 12.2684 10.6424Zm-39.9092 0c0 6.3067-1.4289 10.9381-4.2866 13.8944-2.7591 2.8576-7.0949 4.2865-13.0074 4.2865C4.48363 56.612 0 52.2269 0 43.4567 0 28.577 3.54749 17.6389 10.6425 10.6425 17.836 3.54749 27.9857 0 41.0917 0L39.318 10.6425c-3.5475 0-7.6862 1.5274-12.4162 4.5821-4.6315 2.9563-7.4399 7.1443-8.4253 12.5641 8.1789 0 12.2684 3.5474 12.2684 10.6424Z'/%3E%3C/svg%3E");
            background-size: 46px 32px;
            width: 46px;
            height: 32px;
            position: absolute;
            top: 0;
            left: 0;
        }

        & + blockquote{
            margin-top: 64px;
        }

        & > p{
            font-size: 22px;
            line-height: 30px;
            letter-spacing: 0.3px;
            font-weight: 500;
        }

        cite{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            font-style: normal;

            .rtnu-testimonial--person{
                display: flex;
                align-items: center;

                img{
                    margin-right: 12px;
                    width: 56px;
                    height: 56px;
                    border-radius: 30px;
                }

                p{
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.3px;
                    color: var(--c-light-blue);
                    font-weight: 500;
                    max-width: 100px;
                }
            }

            .rtnu-testimonial--logo{
                max-width: 111px;
            }
        }
    }

    @media screen and (min-width: 990px) {
        padding: 128px 0;

        blockquote{
            margin: 0 auto;
            padding: 0 0 0 105px;
            max-width: 897px;
            position: relative;
    
            &::before{
                background-size: 81px 57px;
                width: 81px;
                height: 57px;
            }
    
            & + blockquote{
                margin-top: 104px;
            }
    
            & > p{
                font-size: 36px;
                line-height: 46px;
                font-weight: 400;
            }
    
            cite{
                margin-top: 32px;
    
                .rtnu-testimonial--person{
                    img{
                        margin-right: 16px;
                        width: 81px;
                        height: 81px;
                        border-radius: 40px;
                    }
    
                    p{
                        font-size: 20px;
                        max-width: 100%;
                    }
                }
    
                .rtnu-testimonial--logo{
                    max-width: 100%;
                }
            }
        }
    }
}

.rtnu-work{
    padding: 56px 0 64px;
    background: var(--c-primary);
    color: var(--c-dark-blue-2);
    text-align: center;

    h2{
        color: inherit;
        margin-bottom: 32px;
    }

    img{
        display: block;
    }

    .rtnu-logos-desktop{
        display: none;
        margin: 0 auto;
    }

    .rtnu-logos-mobile{
        display: block;
        margin: 0 auto;
    }

    @media screen and (min-width: 990px) {
        padding: 96px 0;

        h2{
            font-size: 52px;
            line-height: 44px;
            margin-bottom: 48px;
        }

        .rtnu-logos-desktop{
            display: block;
        }
    
        .rtnu-logos-mobile{
            display: none;
        }
    }
}

.rtnu-cover-image{
    background-image: url('./images/jlo-sc-mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 355px;

    @media screen and (min-width: 600px) {
        min-height: 500px;
    }

    @media screen and (min-width: 834px) {
        background-image: url('./images/jlo-sc-desktop.jpg');
        min-height: 700px;
    }

    @media screen and (min-width: 1600px) {
        min-height: 900px;
    }

    @media screen and (min-width: 2000px) {
        min-height: 1100px;
    }
}

.rtnu-seo-social-proof{
    background: var(--c-dark-blue-1);
    padding: 0 0 64px;
    position: relative;

    .rtnu-seo-social-proof__image{
        margin-left: -20px;
        margin-right: -20px;
    }

    .rtnu-seo-social-proof__content{
        margin-top: 30px;

        & > p{
            font-size: 22px;
            line-height: 30px;
            font-weight: 500;
        }

        cite{
            font-style: normal;
            display: flex;
            align-items: flex-start;
            margin-top: 32px;

            & > img{
                width: 56px;
                height: 56px;
                margin-right: 12px;
            }

            div{  
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                p{
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.3px;
                    font-weight: 500;
                    margin-bottom: 4px;
                }

                span{
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.3px;
                    font-weight: 500;
                    color: var(--c-light-blue);
                }

                img{
                    height: 40px;
                    width: auto;
                    object-fit: contain;
                    margin-top: 16px;
                }
            }
        }
    }

    @media screen and (min-width: 990px) {
        padding: 96px 0;

        .rtnu-wrapper--lg{
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            position: relative;
        }

        .rtnu-seo-social-proof__image{
            margin-left: 0;
            margin-right: 0;
            width: 649px;
            height: 390px;
            position: absolute;
            right: 0;
            z-index: 1;
        }

        .rtnu-seo-social-proof__content{
            margin-top: 0;
            margin-right: auto;
            max-width: 747px;
            flex: 1;
            position: relative;
            z-index: 2;
    
            & > p{
                font-size: 36px;
                line-height: 46px;
                font-weight: 400;
            }
    
            cite{
                margin-top: 48px;
    
                & > img{
                    width: 80px;
                    height: 80px;
                    margin-right: 32px;
                }
    
                div{      
                    p{
                        font-size: 20px;
                        margin-bottom: 8px;
                    }
    
                    span{
                        font-size: 18px;
                    }
    
                    img{
                        height: 60px;
                        margin-top: 24px;
                    }
                }
            }
        }
    }
}

.rtnu-stepper{
    --circle-size: 50px;
    --spacing: 0px;

    margin: 0 auto;
    padding: 0;
    max-width: 800px;

    .rtnu-stepper__item {
        position: relative;
        display: flex;
        padding-bottom: 48px;
      
        &:before {
            content: "";
            background-image: var(--stepper-icon-bg);
            background-repeat: no-repeat;
            background-position: center;
            flex: 0 0 var(--circle-size);
            height: var(--circle-size);
            border-radius: 50%;
            background-color: var(--c-primary);
        }
      
        &:not(:last-child) {
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: calc(var(--circle-size) + var(--spacing));
                bottom: var(--spacing);
                z-index: -1;
                transform: translateX(calc(var(--circle-size) / 2));
                width: 1px;
                background-color: var(--c-primary);
            }
        }

        &:last-child{
            padding-bottom: 0;
        }
    }

    .rtnu-stepper__content {
        max-width: 687px;
        margin-left: 20px;
        
        .rtnu-stepper__title {
            font-size: 32px;
            line-height: 30px;
            margin-bottom: 16px;
            letter-spacing: -1.3px;
        }
          
        p {
            font-size: 18px;
            line-height: 26px;
            color: var(--c-light-blue);
        }

        .btn--link{
            padding-left: 0;
            margin-top: 24px;
        }
    }

    @media screen and (min-width: 900px) {
        --circle-size: 80px;

        .rtnu-stepper__item {
            padding-bottom: 112px;
        }

        .rtnu-stepper__content {
            margin-left: 32px;
            
            .rtnu-stepper__title {
                font-size: 48px;
                line-height: 42px;
                margin-bottom: 24px;
                letter-spacing: -2px;
            }
              
            p {
                font-size: 26px;
                line-height: 36px;
            }
    
            .btn--link{
                margin-top: 32px;
                font-size: 20px;
                line-height: 22px;

                &::before{
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='24' fill='none' viewBox='0 0 21 24'%3E%3Cpath fill='%2355D348' d='m17.5787 15.2092-2.9165-2.3295L6.02923 24l-6.02923381-4.4222L8.9517 8.29425 6.02905 5.95862 20.2808 0l-2.7021 15.2092Z'/%3E%3C/svg%3E");
                    width: 21px;
                    height: 24px;
                    position: absolute;
                    right: 0;
                    top: calc(50% - 8px);
                    will-change: transform;
                    transition: all 0.2s ease;
                    transform: translate(-6px,4px) scale(0.5);
                    transform-origin: bottom left;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                }

                &:hover{
                    text-decoration: underline;

                    &::before{
                        transform: translate(0,0) scale(1);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.rtnu-seo-process{
    padding: 64px 0;

    h2{
        text-align: center;
        margin-bottom: 56px;
    }

    .rtnu-seo-team{
        margin: 80px 0 0;

        h2{
            margin-bottom: 32px;
        }

        .rtnu-grid{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px 20px;
        }

        .rtnu-grid__item{
            text-align: center;

            svg{
                width: 72px;
                height: 72px;
                margin-bottom: 16px;
            }

            h3{
                font-size: 21px;
                line-height: 20px;
            }
        }
    }
      
    @media screen and (min-width: 900px) {
        padding: 112px 0;

        h2{
            max-width: 993px;
            margin: 0 auto 104px;
            font-size: 64px;
            line-height: 56px;
        }

        .rtnu-seo-team{
            margin: 168px 0 0;
    
            h2{
                margin-bottom: 72px;
            }
    
            .rtnu-grid{
                max-width: 960px;
                margin: -28px auto 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-items: flex-start;
                grid-template-columns: unset;
                gap: unset;
            }
    
            .rtnu-grid__item{
                margin: 28px 24px;
    
                svg{
                    width: 88px;
                    height: 88px;
                    margin-bottom: 24px;
                }
    
                h3{
                    font-size: 36px;
                    line-height: 32px;
                    letter-spacing: 0;
                }
            }
        }
    }
}

.rtnu-sites-overview{
    text-align: center;
    padding: 72px 0;
    background: var(--c-dark-blue-1);

    h2{
        margin-bottom: 40px;
        font-size: 36px;
        line-height: 32px;
    }

    .rtnu-grid{
        max-width: 600px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 20px;
    }

    .rtnu-grid__item{
        text-align: center;

        svg{
            width: 72px;
            height: 72px;
            margin-bottom: 16px;
        }

        h3{
            font-size: 21px;
            line-height: 20px;
        }
    }

    @media screen and (min-width: 900px) {
        padding: 120px 0 128px;

        h2{
            font-size: 56px;
            line-height: 50px;
            margin-bottom: 64px;
        }

        .rtnu-grid{
            max-width: 1008px;
            grid-template-columns: repeat(3, 1fr);
            gap: 40px 20px;
        }

        .rtnu-grid__item{
            svg{
                width: 88px;
                height: 88px;
                margin-bottom: 24px;
            }

            h3{
                font-size: 36px;
                line-height: 32px;
                letter-spacing: 0;
            }
        }
    }
}

.rtnu-hs{
    background: #BFAC94;

    .rtnu-hs__top{
        padding: 56px 0;
        background: #BFAC94 url('./images/hs-background.jpg') no-repeat top center;
        background-size: cover;
        min-height: 600px;
    
        img{
            height: 48px;
            width: auto;
            margin: 0 auto;
            display: block;
        }
    
        h2{
            background: -webkit-linear-gradient(white 20%,#FFCD7C 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;
            margin-top: 151px;
        }
    }

    .rtnu-hs__bottom{
        padding: 0 0 76px;

        .rtnu-grid{
            display: grid;
            grid-template-columns: 1fr;
            gap: 32px;
            max-width: 600px;
            margin: 0 auto;
        }

        .rtnu-grid__item{
            display: grid;
            grid-template-columns: 42px 1fr;
            gap: 22px;

            span{
                font-size: 44px;
                line-height: 1;
            }

            h3{
                font-size: 30px;
                line-height: 1;
                letter-spacing: -1.5px;
                color: #2D2115;
            }
        }

        .btn{
            margin-top: 40px;
            background: #2D2115;
            color: #F3D9AD;
            font-size: 24px;
            line-height: 22px;
            letter-spacing: -1px;
            height: 66px;

            &:hover{
                background: #090603;
            }
        }
    }

    @media screen and (min-width: 750px) {
        .rtnu-hs__top{
            padding: 80px 0 0;
            min-height: 900px;

            img{
                height: 64px;
            }
        
            h2{
                font-size: 74px;
                line-height: 60px;
                margin-top: 96px;
            }
        }

        .rtnu-hs__bottom{
    
            .rtnu-grid__item{
                gap: 32px;

                span{
                    font-size: 48px;
                }

                h3{
                    font-size: 44px;
                    line-height: 38px;
                }
            }
    
            .btn{
                max-width: 335px;
                margin: 0 auto;
                margin-top: 40px;
                font-size: 26px;
                line-height: 24px;
                height: 76px;
            }
        }
    }

    @media screen and (min-width: 990px) {
        .rtnu-hs__bottom{
            padding: 0 0 104px;
    
            .rtnu-grid{
                grid-template-columns: repeat(3, 1fr);
                max-width: 100%;
            }
    
            .rtnu-grid__item{
                display: grid;
                grid-template-columns: 1fr;
                gap: 24px;
                justify-items: center;
                text-align: center;
            }
        }
    }

    @media screen and (min-width: 1350px) {
        .rtnu-hs__top{
            min-height: 1000px;
            background-position: bottom center;
        }
    }
    
    @media screen and (min-width: 1500px) {
        .rtnu-hs__top{
            min-height: 1300px;
        }
    }
}

@keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
}

@media (prefers-reduced-motion) {
    @keyframes float {
        0% {
          transform: translatey(0px);
        }
        100% {
          transform: translatey(0px);
        }
    }
}

.rtnu-immi{
    background: #FFE800;
    padding: 64px 0 72px;
    text-align: center;
    overflow: hidden;

    .rtnu-immi_accent-1,
    .rtnu-immi_accent-2,
    .rtnu-immi_accent-3,
    .rtnu-immi_accent-4,
    .rtnu-immi_accent-5,
    .rtnu-immi_accent-6{
        position: absolute;
        z-index: 3;
        animation-name: float;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 5s;
    }

    .rtnu-immi_accent-1{
        display: none;
        animation-delay: 600ms;
    }
    .rtnu-immi_accent-2{
        width: 94px;
        height: 79px;
        top: 32px;
        right: -36px;
        animation-delay: 1s;
    }
    .rtnu-immi_accent-3{
        width: 63px;
        height: 55px;
        bottom: 200px;
        left: -8px;
        animation-delay: 700ms;
    }
    .rtnu-immi_accent-4{
        width: 47px;
        height: 30px;
        bottom: 220px;
        right: 8px;
        animation-delay: 1.6s;
    }
    .rtnu-immi_accent-5{
        width: 55px;
        height: 63px;
        top: 32px;
        left: 11px;
        animation-delay: 2s;
    }
    .rtnu-immi_accent-6{
        display: none;
        animation-delay: 1.1s;
    }

    .rtnu-immi__logo{
        display: block;
        margin: 0 auto 48px;
        height: 64px;
        width: auto;
        object-fit: contain;
    }

    .rtnu-immi__packs{
        display: block;
        margin: 0 auto 32px;
        object-fit: contain;
    }

    h2{
        color: var(--c-black);
    }

    a{
        font-size: 15px;
        line-height: 18px;
        color: var(--c-black);
        margin-top: 24px;
        text-decoration: underline;

        &:hover{
            text-decoration: none;
        }
    }

    @media screen and (min-width: 900px) {
        padding: 80px 0 128px;

        .rtnu-wrapper--lg{
            max-width: 1100px;
        }

        .rtnu-immi_accent-1{
            display: block;
            top: 122px;
            left: 0;
            width: 67px;
            height: 65px;
        }
        .rtnu-immi_accent-2{
            width: 170px;
            height: 139px;
            top: 117px;
            right: 0;
        }
        .rtnu-immi_accent-3{
            width: 79px;
            height: 70px;
            bottom: 50%;
            left: 0;
        }
        .rtnu-immi_accent-4{
            width: 81px;
            height: 52px;
            bottom: 45%;
            right: 0;
        }
        .rtnu-immi_accent-5{
            width: 116px;
            height: 133px;
            top: unset;
            bottom: -32px;
            left: -32px;
        }
        .rtnu-immi_accent-6{
            display: block;
            width: 80px;
            height: 106px;
            bottom: 72px;
            right: 0;
        }

        .rtnu-immi__logo{
            margin: 0 auto 56px;
            height: 80px;
        }

        h2{
            font-size: 64px;
            line-height: 56px;
            letter-spacing: -1px;
            max-width: 816px;
            margin: 0 auto;
        }
    
        a{
            font-size: 20px;
            line-height: 22px;
            margin-top: 32px;
        }
    }
}

.rtnu-kf{
    padding: 142px 0 72px;
    text-align: center;
    background: #FAF9F5 url('./images/kf-header-bg.jpg') no-repeat top center;
    background-size: auto 260px;

    .rtnu-kf__logo{
        display: block;
        margin: 0 auto 32px;
        height: 34px;
        width: auto;
        object-fit: contain;
    }

    .rtnu-kf__products{
        display: grid;
        grid-template-columns: 1fr 1.05fr 1fr;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 24px;

        img{
            &:nth-child(2){
                max-width: 110%;
            }
            &:nth-child(3){
                margin-left: 12px;
            }
        }
    }

    h2{
        color: var(--c-black);
    }

    a{
        font-size: 15px;
        line-height: 18px;
        color: var(--c-black);
        margin-top: 24px;
        text-decoration: underline;

        &:hover{
            text-decoration: none;
        }
    }

    @media screen and (min-width: 900px) {
        padding: 188px 0 128px;
        background-size: 100% auto;

        .rtnu-kf__logo{
            margin: 0 auto 72px;
            height: 52px;
        }

        .rtnu-kf__products{
            grid-template-columns: 1fr 1.1fr 1fr;
            max-width: 766px;
            margin: 0 auto 32px;
    
            img{
                &:nth-child(2){
                    max-width: 100%;
                }
                &:nth-child(3){
                    margin-left: 0;
                }
            }
        }

        h2{
            font-size: 64px;
            line-height: 56px;
            letter-spacing: -1px;
            max-width: 816px;
            margin: 0 auto;
        }
    
        a{
            font-size: 20px;
            line-height: 22px;
            margin-top: 32px;
        }
    }

    @media screen and (min-width: 1440px) {
        padding: 280px 0 128px;
    }
}

.rtnu-what-we-do{
    padding: 80px 0 72px;

    h2{
        text-align: center;
        margin-bottom: 24px;

        & + p{
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: var(--c-light-blue);
            margin-bottom: 56px;
        }
    }

    @media screen and (min-width: 900px) {
        padding: 144px 0 128px;

        h2{
            font-size: 64px;
            line-height: 56px;
            margin-bottom: 40px;
    
            & + p{
                font-size: 32px;
                line-height: 46px;
                max-width: 996px;
                margin: 0 auto 98px;
            }
        }
    }
}

.rtnu-different{
    padding: 72px 0;
    border-top: 1px solid #17304B;

    h2{
        text-align: center;
        margin-bottom: 24px;

        & + p{
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: var(--c-light-blue);
            margin-bottom: 56px;
        }
    }

    .rtnu-grid{
        display: grid;
        grid-template-columns: 1fr;
        gap: 56px;
    }
    
    .rtnu-grid__item{
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        text-align: center;

        .icon{
            margin-bottom: 24px;
            width: 100%;
            height: 40px;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .content{
            h3{
                margin-bottom: 16px;
                font-size: 32px;
                line-height: 30px;
            }
    
            p{
                margin-bottom: 0;
                color: var(--c-light-blue);
                font-size: 18px;
                line-height: 26px;
            }
        }
    }

    @media screen and (min-width: 900px) {
        padding: 144px 0 128px;
        
        h2{
            font-size: 64px;
            line-height: 56px;
            margin-bottom: 40px;
    
            & + p{
                font-size: 32px;
                line-height: 46px;
                max-width: 996px;
                margin: 0 auto 98px;
            }
        }

        .rtnu-grid{
            gap: 72px;
            max-width: 792px;
            margin: 0 auto;
        }

        .rtnu-grid__item{
            grid-template-columns: 72px 1fr;
            gap: 36px;
            justify-items: start;
            text-align: left;
    
            .icon{
                margin-bottom: 0;
                height: 56px;
            }
    
            .content{
                h3{
                    margin-bottom: 24px;
                    font-size: 48px;
                    line-height: 44px;
                }
        
                p{
                    font-size: 26px;
                    line-height: 36px;
                }
            }
        }
    
    }
}

.rtnu-social-proofs{
    padding: 64px 0 72px;
    background: var(--c-dark-blue-1);

    .rtnu-grid{
        max-width: 600px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        gap: 64px;
    }

    .rtnu-grid__item{
        text-align: center;

        svg{
            height: 32px;
            width: auto;
            margin-bottom: 24px;
        }

        h2{
            font-size: 34px;
            line-height: 1;
            letter-spacing: -1px;
            margin-bottom: 20px;
        }

        cite{
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: left;

            & > img{
                width: 72px;
                height: 72px;
                margin-right: 16px;
            }

            p{
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                color: var(--c-light-blue);
                margin-bottom: 16px;
            }
        }
    }

    @media screen and (min-width: 900px) {
        padding: 120px 0 128px;

        .rtnu-grid{
            max-width: 100%;
            grid-template-columns: repeat(2, 1fr);
            gap: 80px 32px;
        }

        .rtnu-grid__item{
            svg{
                height: 40px;
                margin-bottom: 32px;
            }
    
            h2{
                font-size: 48px;
                margin-bottom: 24px;
            }
        }
    }
}

.rtnu-service-block{
    padding: 64px 0 56px;
    border-bottom: 1px solid #1E3247;
    

    &.no-border{
        border-bottom: none;
    }

    .rtnu-wrapper{
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        text-align: center;
    }

    .rtnu-service-block__icon{
        margin-bottom: 24px;
        width: auto;
        height: 64px;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .rtnu-service-block__content{
        h2{
            margin-bottom: 16px;
        }

        p{
            margin-bottom: 32px;
            color: var(--c-light-blue);
        }

        &.longer{
            p{
                max-width: 800px;
            }
        }
    }

    @media screen and (min-width: 990px) {
        padding: 128px 0 104px;

        .rtnu-wrapper{
            max-width: 975px;
            grid-template-columns: 103px 1fr;
            gap: 44px;
            justify-items: start;
            text-align: left;
        }
        
        .rtnu-service-block__icon{
            margin-bottom: 0;
            width: 102px;
            height: 102px;
        }

        .rtnu-service-block__content{
            h2{
                font-size: 74px;
                line-height: 64px;
                margin-bottom: 32px;
            }
            p{
                margin-bottom: 32px;
                font-size: 32px;
                line-height: 42px;
                letter-spacing: 0.3px;
            }

            .btn--link{
                padding-left: 0;
                font-size: 24px;
                line-height: 30px;
                position: relative;

                &::before{
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='24' fill='none' viewBox='0 0 21 24'%3E%3Cpath fill='%2355D348' d='m17.5787 15.2092-2.9165-2.3295L6.02923 24l-6.02923381-4.4222L8.9517 8.29425 6.02905 5.95862 20.2808 0l-2.7021 15.2092Z'/%3E%3C/svg%3E");
                    width: 21px;
                    height: 24px;
                    position: absolute;
                    right: 0;
                    top: calc(50% - 8px);
                    will-change: transform;
                    transition: all 0.2s ease;
                    transform: translate(-6px,4px) scale(0.5);
                    transform-origin: bottom left;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                }

                &:hover{
                    text-decoration: underline;

                    &::before{
                        transform: translate(0,0) scale(1);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.rtnu-cta{
    @media screen and (min-width: 750px) {
        .btn{
            max-width: 440px;
            margin: 0 auto;
            height: 106px;
            font-size: 44px;
            line-height: 38px;
        }
    }
}

.rtnu-footer-home{
    margin-top: 72px;
    padding: 64px 0 0;
    background: var(--c-dark-blue-1);

    .rtnu-grid{
        display: flex;
        flex-direction: column-reverse;
    }

    .rtnu-grid__item{
        &:nth-child(2){
            margin-bottom: 24px;
        }
    }

    blockquote{
        margin: 0; 
        padding: 0;
    
        p{
            font-family: var(--font-heading);
            text-transform: uppercase;
            letter-spacing: -1px;
            font-size: 38px;
            line-height: 36px;
            font-style: italic;
            text-align: center;
        }
    
        cite{
            font-style: normal;
            color: var(--c-white);
            text-align: center;
    
            strong{
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 0.2px;
                font-weight: 500;
                display: block;
                margin-top: 32px;
            }
            span{
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.1px;
                color: var(--c-light-blue);
                display: block;
                margin-top: 8px;
            }
        }
    }

    @media screen and (min-width: 575px) {
        img {
            max-width: 70%;
            margin: 0 auto;
            display: block;
        }
    }

    @media screen and (min-width: 750px) {
        img {
            max-width: 50%;
            margin: 0 auto;
            display: block;
        }
    }

    @media screen and (min-width: 900px) {
        margin-top: 128px;

        .rtnu-wrapper--lg{
            max-width: 1102px;
        }

        .rtnu-grid{
            flex-direction: row;
            align-items: flex-end;
        }

        .rtnu-grid__item{
            &:nth-child(1){
                margin-right: 16px;
                flex: 1;
            }
            &:nth-child(2){
                margin-bottom: 104px;
            }
        }

        img{
            max-width: 463px;
            min-width: 350px;
            width: 100%;
        }

        blockquote{
            text-align: left;

            p{
                letter-spacing: -2px;
                font-size: 70px;
                line-height: 62px;
                text-align: left;
                max-width: 603px;
            }
        
            cite{
                text-align: left;

                strong{
                    margin-top: 40px;
                }
                span{
                    line-height: 22px;
                }
            }
        }
    }
}

.rtnu-footer-jd{
    padding: 72px 0;
    background: var(--c-dark-blue-1);
    text-align: center;

    h2{
        font-size: 48px;
        line-height: 42px;
        margin-bottom: 24px;
    }

    p{
        font-size: 20px;
        line-height: 26px;
        color: var(--c-light-blue);
        margin-bottom: 32px;
    }

    .btn{
        max-width: 390px;
        margin: 0 auto 56px;
    }

    .rtnu-logos-desktop{
        display: none;
        margin: 0 auto;
    }

    .rtnu-logos-mobile{
        display: block;
        margin: 0 auto;
    }

    @media screen and (min-width: 990px) {
        padding: 136px 0 104px;

        h2{
            font-size: 74px;
            line-height: 64px;
            margin-bottom: 32px;
        }
    
        p{
            font-size: 32px;
            line-height: 42px;
            margin: 0 auto 48px;
            max-width: 700px;
        }

        .btn{
            margin: 0 auto 72px;
            height: 106px;
            font-size: 44px;
        }

        .rtnu-logos-desktop{
            display: block;
        }
    
        .rtnu-logos-mobile{
            display: none;
        }
    }
}

.rtnu-footer-cta{
    background: var(--c-dark-blue-1);
    text-align: center;
    padding: 72px 0 56px;

    &.rtnu-footer-cta__sites{
        background: var(--c-dark-blue-3);
    }

    h2{
        margin-bottom: 24px;

        br{
            display: none;
        }
    }

    p{
        font-size: 20px;
        line-height: 26px;
        color: var(--c-light-blue);
        margin-bottom: 32px;

        br{
            display: none;
        }
    }

    .btn{
        max-width: 390px;
        margin: 0 auto 40px;
        height: 108px;
    }

    .rtnu-footer-cta__quote{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 754px;
        margin: 0 auto;

        h3{
            font-size: 20px;
            line-height: 26px;
            letter-spacing: .2px;
            font-weight: 500;
            margin-bottom: 24px;
            font-family: var(--font-body);
            text-transform: initial;
            font-style: normal;
        }

        img{
            height: 40px;
            width: auto;
            object-fit: contain;
        }
    }

    &.rtnu-footer-cta__sites{
        .btn{
            max-width: 395px;
            margin: 0 auto 40px;
            height: 80px;
        }
    }

    @media screen and (min-width: 990px) {
        padding: 136px 0 112px;

        h2{
            font-size: 74px;
            line-height: 64px;
            margin-bottom: 32px;

            br{
                display: block;
            }
        }
    
        p{
            font-size: 32px;
            line-height: 42px;
            margin: 0 auto 48px;
            max-width: 700px;

            br{
                display: block;
            }
        }

        .btn{
            margin: 0 auto 72px;
            height: 106px;
            font-size: 44px;
            max-width: 601px;

            br{
                display: none;
            }
        }

        .rtnu-footer-cta__quote{
            h3{
                font-size: 28px;
                line-height: 37px;
                max-width: 586px;
                margin: 0 auto 24px;
            }
    
            img{
                height: 56px;
            }
        }

        &.rtnu-footer-cta__sites{
            .btn{
                margin: 0 auto 72px;
                height: 106px;
            }
        }
    }
}

.rtnu-footer-cta__lead{
    padding: 64px 0;
    border-top: 1px solid #0F2339;
    background: var(--c-dark-blue-1);
    text-align: center;

    &.rtnu-footer-cta__lead__sites{
        background: var(--c-dark-blue-3);
        border-color: #17304B;
    }

    h3{
        font-size: 30px;
    }

    .btn--outline{
        height: 66px;
        border: 1px solid var(--c-primary);
        background: transparent;
        color: var(--c-primary);
        font-size: 24px;
        line-height: 32px;
        margin-top: 24px;
        margin-bottom: 0;

        &:hover{
            background: var(--c-primary-darker);
            color: var(--c-dark-blue-3);
            border: 1px solid var(--c-primary-darker);
        }
    }

    @media screen and (min-width: 990px) {
        padding: 120px 0 112px;

        h3{
            font-size: 48px;
            line-height: 44px;
            margin-bottom: 40px;
        }

        .btn--outline{
            max-width: 335px;
            height: 82px;
            margin: 0 auto;
            font-size: 26px;
        }
    }
}

.animated {
    opacity: 0;
    visibility: hidden;
    transition: 800ms cubic-bezier(.23,1,.32,1);
    
    &.inview {
      opacity: 1;
      visibility: visible;
      transform: translate(0);
    }
}

.fadeInUp {
    transform:translateY(50px);
}

.fadeInDown {
    transform:translateY(-50px);
}

.fadeInLeft {
    transform:translateX(25px);
}

.fadeInRight {
    transform:translateX(-25px);
}

.zoomIn {
    transform:scale(1.3);
}